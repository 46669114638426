/* @ts-nocheck */

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import url("https://fonts.googleapis.com/css2?family=Cairo&family=Raleway:wght@100;300;500;700&family=Lato&family=Lora&family=Markazi+Text&family=Playfair+Display&display=swap");

body {
  margin: 0;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}

.ant-btn-primary {
  @apply bg-primary;
  /* @apply hover:bg-opacity-70  !important; */
}

/* .ant-picker-input input {
  @apply text-center;
}

.client-date-picker > .ant-picker-panel,
.ant-picker-date-panel,
.ant-picker-body,
.ant-picker-content {
  @apply w-full  !important;
}

.client-date-picker {
  @apply w-[21rem] md:w-[22rem];
}

.collapse-header-class .ant-collapse-header {
  @apply bg-gray-50;
}

.collapse-header-class .ant-collapse-content-box {
  @apply px-1 !important;
}

.client-date-picker
  .ant-picker-header
  > button.ant-picker-header-super-prev-btn,
.client-date-picker
  .ant-picker-header
  > button.ant-picker-header-super-next-btn {
  @apply hidden !important;
} */

/* .ant-btn-default {
  @apply bg-primary text-white;
  @apply hover:bg-opacity-70 hover:text-white  !important;
  @apply min-w-full !important;
} */

.ant-upload-wrapper {
  @apply min-w-full h-full justify-center items-center  !important;
}

.ant-upload {
  @apply min-w-full min-h-full justify-center items-center border-0  !important;
}

.ant-switch {
  @apply bg-gray-200;
}

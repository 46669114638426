.rbc-calendar {
  @apply flex h-full md:flex-row !important;
}

.rbc-month-view {
  @apply flex md:py-10 py-2;
}

.rbc-month-view,
.rbc-row-content,
.rbc-row-bg {
  @apply border-none;
}

.rbc-header + .rbc-header {
  @apply border-b border-0;
}

.rbc-event {
  @apply w-full bg-transparent;
}
